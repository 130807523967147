import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Select, MenuItem } from '@mui/material';

function AdminReportUpload() {

  const [companyID, setCompanyID] = useState('');
  const [file, setFile] = useState(null);
  const [message, setMessage] = useState('');

  const [company, setCompany] = useState([]);



  const handleChange = (event) => {
    setCompanyID(event.target.value);
  };

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const handleUpload = async (e) => {
    e.preventDefault();

    if (!file || !companyID) {
      setMessage("Please select a file and enter a company ID.");
      return;
    }

    const formData = new FormData();
    formData.append('file', file);
    formData.append('companyID', companyID);

    try {
      const response = await axios.post('https://portal.peaksoftcon.com:8081/api/uploadReport', formData, {
        headers: { 'Content-Type': 'multipart/form-data' }
      });
      setMessage(response.data.message);
    } catch (error) {
      console.error('Error uploading file:', error);
      setMessage('File upload failed');
    }
  };

  useEffect(() => {
    axios
      .get('https://portal.peaksoftcon.com:8081/api/companies')
      .then((response) => {
        setCompany(response.data);
        if (response.data.length > 0) {
          setCompanyID(response.data[0].ID); // Set the first company as the selected one
        }
      })
      .catch((error) => console.error('Error fetching data:', error));

  }, []);

  return (
    <div className="container mt-5">
      <h1 className="text-center mb-4">RAPOR YÜKLE</h1>
      <form onSubmit={handleUpload} className="bg-light p-4 rounded shadow-sm">
        <div className="mb-3">
          <label htmlFor="company-select" className="form-label">Şirket</label>
          <Select
            labelId="company-select-label"
            id="company-select"
            value={companyID}
            className="form-select"
            label="Select a Company"
            onChange={handleChange}>
            {company.map((company) => (
              <MenuItem key={company.ID} value={company.ID}>
                {company.Company_Name}
              </MenuItem>
            ))}
          </Select>
        </div>
        <div className="mb-3">
          <label htmlFor="file-upload" className="form-label">Dosya:</label>
          <input
            type="file"
            id="file-upload"
            className="form-control"
            onChange={handleFileChange}
            required
          />
        </div>
        <button type="submit" className="btn btn-primary w-100">Yükle</button>
      </form>
      {message && <p className="text-center mt-3">{message}</p>}
    </div>
  );
}

export default AdminReportUpload;
