import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Select, MenuItem,TextField } from '@mui/material';


function TaskUpload() {

  const [companyID, setCompanyID] = useState('');
  const [taskName, setTaskName] = useState('');
  const [taskDescription, setTaskDescription] = useState('');
  const [taskState, setTaskState] = useState(0);  
  const [message, setMessage] = useState('');
  const [company, setCompany] = useState([]);

  const [emailData, setEmailData] = useState({    
    subject: 'Deneme',
    htmlFormat: '<p>Deneme Maili <p>',
  });

  const generateEmailHTML = () => {    
    const companyImageUrl ="https://www.peaksoftcon.com/img/Peak_Logo_Kobalt.png";
    return `
      <!DOCTYPE html>
      <html lang="en">
      <head>
        <meta charset="UTF-8">
        <meta name="viewport" content="width=device-width, initial-scale=1.0">
        <title>Yeni Bir Task</title>
        <style>
          body { font-family: Arial, sans-serif; color: #333; }
          .container { max-width: 600px; margin: 0 auto; background-color: #ffffff; border-radius: 8px; }
          .header { background-color: #4CAF50; padding: 20px; color: #ffffff; text-align: center; }
          .content { padding: 20px; }
          .button { display: inline-block; padding: 10px 20px; color: #ffffff; background-color: #4CAF50; text-decoration: none; border-radius: 5px; }
          .footer { background-color: #f4f4f4; padding: 10px; text-align: center; font-size: 12px; color: #777; }
        </style>
      </head>
      <body>
        <div class="container">
         <img src="${companyImageUrl}" alt="Company Logo" class="logo" style="max-width: 150px; height: auto; display: block; margin: 0 auto;" />
          <div class="header"><h1>Task Notification</h1></div>
          <div class="content">
            <p>Hello,</p>
            <p>You have been assigned a new task. Here are the details:</p>
            <table>
              <tr><td><strong>Task Name:</strong></td><td>${taskName}</td></tr>
              <tr><td><strong>Description:</strong></td><td>${taskDescription}</td></tr>                            
            </table>
            <p>Please log in to the system to view the full task details.</p>
            <a href="https://portal.peaksoftcon.com" class="button">Go to Task Portal</a>
          </div>
          <div class="footer">
            <p>&copy; 2024 Peak Yazılım ve Danışmanlık. All rights reserved.</p>
            <p>Üniversiteler Mahallesi 1596. Cad. Hacettepe Teknokent Kuluçka Merkez Binası No:6C/10 Çankaya/ANKARA</p>
          </div>
        </div>
      </body>
      </html>
    `;
  };

  


  const handleChange = (event) => {
    setCompanyID(event.target.value);
    setEmailData({
        ...emailData,    
        sendTo: company[(event.target.value)-1].Company_Mail,
      });
  };
  

  const handleUpload = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post('https://portal.peaksoftcon.com:8081/api/uploadTask', {
        Task_Name: taskName,
        Task_Status: taskState,
        Company_ID: companyID,
        Task_Description: taskDescription,
      });

      setMessage('Task successfully uploaded');



    } catch (error) {
      console.error('Error uploading task:', error);
      setMessage('Error uploading task');
    }
    
    
    try{
    const response = await axios.post('https://portal.peaksoftcon.com:8081/api/sendMail', {
        sendTo: emailData.sendTo,
        subject: taskName,
        htmlFormat:generateEmailHTML(),
    });
    }
    catch (error) {
        console.error('Error sending mail:', error);
        setEmailData('Error sending Mail');
    }
    
  };


  useEffect(() => {
    axios
      .get('https://portal.peaksoftcon.com:8081/api/companies')
      .then((response) => {
        setCompany(response.data);
        if (response.data.length > 0) {
          setCompanyID(response.data[0].ID); // Set the first company as the selected one
          setEmailData({        
            sendTo: response.data[0].Company_Mail,
            subject: '',
            htmlFormat: '',
          });
        }
      })
      .catch((error) => console.error('Error fetching data:', error));

  }, []);

  return (
    <div className="container mt-5">
      <h1 className="text-center mb-4">GÖREV ATAMA</h1>
      <form onSubmit={handleUpload} className="bg-light p-4 rounded shadow-sm">
        <div className="mb-3">
          <label htmlFor="company-select" className="form-label">Şirket</label>
          <Select
            labelId="company-select-label"
            id="company-select"
            value={companyID}
            className="form-select"
            label="Select a Company"
            onChange={handleChange}>
            {company.map((company) => (
              <MenuItem key={company.ID} value={company.ID}>
                {company.Company_Name}
              </MenuItem>
            ))}
          </Select>
        </div>

        <div className="mb-3">
          <TextField
            label="Görev Adı"
            value={taskName}
            onChange={(e) => setTaskName(e.target.value)}
            fullWidth
          />
        </div>

        <div className="mb-3">
          <TextField
            label="Görev Açıklaması"
            value={taskDescription}
            onChange={(e) => setTaskDescription(e.target.value)}
            fullWidth
          />
        </div>

        <div className="mb-3">
          <label htmlFor="task-state" className="form-label">Görev Durumu</label>
          <Select
            id="task-state"
            value={taskState}
            onChange={(e) => setTaskState(e.target.value)}
            fullWidth>
            <MenuItem value={0}>Başlamadı</MenuItem>
            <MenuItem value={1}>Devam Ediyor</MenuItem>
            <MenuItem value={2}>Tamamlandı</MenuItem>
          </Select>
        </div>
     
        <button type="submit" className="btn btn-primary w-100">Yükle</button>
      </form>
      {message && <p className="text-center mt-3">{message}</p>}
    </div>
  );
}

export default TaskUpload;
