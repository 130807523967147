import React, { useEffect, useState } from 'react';
import axios from 'axios';
import 'bootstrap/dist/css/bootstrap.min.css';

function Notifications() {

  const [notifications, setNotifications] = useState([]);

   

  useEffect(() => {
    // Fetch notifications from your backend API
    axios.get('https://portal.peaksoftcon.com:8081/api/notifications') // Update the endpoint as needed
      .then((response) => {
        setNotifications(response.data);
      })
      .catch((error) => {
        console.error('Error fetching notifications:', error);
      });
  }, []);

  return (
    <div className="container mt-4">
      <h2 className="text-center mb-4">Notifications</h2>
      <div className="row">
        {notifications.map((notification, index) => (
          <div className="col-12 col-md-6 col-lg-4 mb-3" key={index}>
            <div className="card shadow-sm h-100">
              <div className="card-body">
                <h5 className="card-title">{notification.Notification_Name}</h5>
                  <p className="card-text" onClick={() => window.open(notification.Notification_Url, '_blank')} style={{ cursor: 'pointer', color: 'inherit', textDecoration: 'none' }}>
                    {notification.Notification_Description}
                  </p>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );


}

export default Notifications