// Sidebar.js
import React from 'react';

function Sidebar({ onSelect }) {

    const handleMenuClick = (tabIndex) => {
        onSelect(tabIndex);
      };

  return (
    <div
      className="bg-dark text-white  p-3 rounded-end" style={{ width: '200px',height: '50vh',borderWidth: '2px' }}>
      <h2 className="h5 text-center rounded-end border border-light">Yönetici Paneli</h2>
      <ul className="list-unstyled">       
        <li
          onClick={() => handleMenuClick(0)}
          className="p-2 rounded btn btn-dark w-100 text-start mb-2 sidebar-btn text-center">
          Şirket Ekle / Sil
        </li>
        <li
          onClick={() => handleMenuClick(1)}
          className="p-2 rounded btn btn-dark w-100 text-start mb-2 sidebar-btn text-center">
          Raporlar
        </li>
        <li
          onClick={() => handleMenuClick(2)}
          className="p-2 rounded btn btn-dark w-100 text-start mb-2 sidebar-btn text-center">
          Görevler
        </li>
        <li
          onClick={() => handleMenuClick(3)}
          className="p-2 rounded btn btn-dark w-100 text-start mb-2 sidebar-btn text-center">
          Bildirimler
        </li>
      </ul>
    </div>
  );
}

export default Sidebar;
