import React from 'react'
import 'bootstrap/dist/css/bootstrap.min.css'
import ClientInformations from './ClientPage'
import AdminPage from './AdminPage'
import Reports from './Reports'
import Notifications from './Notifications'
import LoginPage from './LoginPage'
import { useNavigate } from "react-router-dom";
import { useAuth } from "../provider/authProvider";

function MainBody({ data }) {
    
    const { setToken } = useAuth();
    const navigate = useNavigate();

    const handleLogout = () => {
        setToken();
        navigate("/", { replace: true });
      };

    const companyInfo = JSON.parse(sessionStorage.getItem('companyInfo'));


    if (!companyInfo) {
        
        handleLogout();
        return <div>Loading...</div>;
      }

    
    return (

        
        <div id="MainBody" className="container-fluid bg-light py-4 vh-100">
            
            {data === 0 && companyInfo.role === 'Admin' && <AdminPage />}
            {data === 0 && companyInfo.role === 'Client' && <ClientInformations />}
            {data === 1 && <Reports />}
            {data === 2 && <Notifications />}
        </div>
    )
}

export default MainBody
