
import React, { useState } from 'react';
import AdminSideBar from './AdminSideBar';
import AdminContent from './AdminContent';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../App.css';

function AdminPage() {
  const [selectedContent, setSelectedContent] = useState(0);

  return (
    <div className="d-flex">
      <AdminSideBar onSelect={setSelectedContent} />
      <div className="flex-grow-1">
        <AdminContent content={selectedContent} />
      </div>
    </div>
  );
}

export default AdminPage;
