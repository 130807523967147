// DocumentPreview.jsx
import React, { useEffect, useState } from 'react';
import axios from 'axios';

function DocumentPreview({ document }) {
  const [fileContent, setFileContent] = useState('');
  const [isLoading, setIsLoading] = useState(true);

  const getFileExtension = (fileName) => {
    return fileName.split('.').pop().toLowerCase();
  };

  const fileExtension = getFileExtension(document.name);
  const documentUrl = document.url; // The URL already includes the full path

  useEffect(() => {
    // Reset state when document changes
    setFileContent('');
    setIsLoading(true);

    if (['txt', 'md', 'json'].includes(fileExtension)) {
      // Fetch text file content
      axios
        .get(documentUrl)
        .then((response) => {
          setFileContent(response.data);
          setIsLoading(false);
        })
        .catch((error) => {
          console.error('Error fetching file content:', error);
          setIsLoading(false);
        });
    } else {
      // For other file types, no additional fetching is needed
      setIsLoading(false);
    }
  }, [document, fileExtension, documentUrl]);

  if (isLoading) {
    return <p>Loading preview...</p>;
  }

  return (
    <div>
    <h5 className="mb-3">Görüntülenen Döküman <a href={documentUrl} target="_blank" rel="noopener noreferrer">
            "{document.name}"
            </a></h5>
    {(() => {
      if (['png', 'jpg', 'jpeg', 'gif', 'bmp', 'svg'].includes(fileExtension)) {
        // **Image file preview**
        return (
          <img
            src={documentUrl}
            alt={document.name}
            className="img-fluid"
            style={{ maxWidth: '100%', height: 'auto' }}
          />
        );
      } else if (fileExtension === 'pdf') {
        // **PDF file preview**
        return (
          <div className="ratio ratio-1x1">
            <iframe
              src={documentUrl}
              title={document.name}
              className="embed-responsive-item"
              style={{ border: 'none'}}
            />
          </div>
        );
      } else if (['txt', 'md', 'json'].includes(fileExtension)) {
        // **Text file preview**
        return (
          <pre
            className="p-3 bg-light rounded"
            style={{ whiteSpace: 'pre-wrap', wordWrap: 'break-word' }}
          >
            {fileContent}
          </pre>
        );
      } else if (['mp4', 'webm', 'ogg'].includes(fileExtension)) {
        // **Video file preview**
        return (
            <div className="ratio ratio-16x9">
           <video controls className="w-100 h-100" style={{ objectFit: 'cover' }}>
              <source src={documentUrl} type={`video/${fileExtension}`} />
              Your browser does not support the video tag.
            </video>
          </div>
        );
      } else if (['mp3', 'wav', 'ogg'].includes(fileExtension)) {
        // **Audio file preview**
        return (
          <div>
            <audio controls className="w-100">
              <source src={documentUrl} type={`audio/${fileExtension}`} />
              Your browser does not support the audio element.
            </audio>
          </div>
        );
      } else if (['doc', 'docx', 'xls', 'xlsx', 'ppt', 'pptx'].includes(fileExtension)) {
        // **Office document preview using Google Docs Viewer**
        const encodedUrl = encodeURIComponent(documentUrl);
        const officeViewerUrl = `https://view.officeapps.live.com/op/embed.aspx?src=${encodedUrl}`;
        return (
            <div className="ratio ratio-1x1">            
            <iframe
              src={officeViewerUrl}
              title={document.name}
              width="100%"
              height="100%"
              style={{ border: 'none' }}
            />            
          </div>
        );
      } else {
        // **Unsupported file type**
        return (
          <p>
            Preview not available for this file type.{' '}
            <a href={documentUrl} target="_blank" rel="noopener noreferrer">
              Download
            </a>
          </p>
        );
      }
    })()}
  </div>
  );
}

export default DocumentPreview;
