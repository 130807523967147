import React from "react";
import TaskList from './TaskList'
import LoginPage from './LoginPage'

function ClientInformations({ company })
{

  const companyInfo = JSON.parse(sessionStorage.getItem('companyInfo'));

  if (!companyInfo) {
    // return <div>Loading...</div>;
    return <LoginPage/>
  }

    return(

        <div id="CompanyInformations" className="container">        
        <div className="row">
          <div className="col-md-6">
             <div className="p-4 bg-light border">
               <h2>Şirket Bilgileri</h2>
               <p>{companyInfo.fullname}</p>
               <h2>Address</h2>
               <p>{companyInfo.address}</p>
               <h2>Description</h2>
               <p>---------------------</p>
               <h2>Contact Information</h2>
               <p>Email: {companyInfo.mail}<br/>Phone: {companyInfo.phone}</p>
             </div>
          </div>
          <TaskList/>
        </div>        
      </div>


    );

}

export default ClientInformations