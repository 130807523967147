import React from 'react';
import AdminCompanyEdit from './AdminCompanyEdit';
import AdminReportUpload from './AdminReportUpload';
import TaskUpload from './AdminTask';

function AdminContent({ content }) {
  return (
    <div id="MainBody" className="container-fluid bg-light py-4 vh-100">
      
      {content === 0 && <AdminCompanyEdit />}
      {content === 1 && <AdminReportUpload />}
      {content === 2 && <TaskUpload />}

    </div>
  );
}

export default AdminContent;