import React, { useState } from 'react'
import axios from 'axios'
import { Link,useNavigate } from "react-router-dom";
import { useAuth } from "../provider/authProvider";

function LoginPage() {
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const [loginError, setLoginError] = useState(false);
  const { setToken } = useAuth();
  const navigate = useNavigate();

  const handleLogin = () => {
    setToken("This is a login token.");

    navigate("/", { replace: true });
  };


  function handleSubmit(event) {
    event.preventDefault();
    axios.post('https://portal.peaksoftcon.com:8081/login', { username, password })
      .then(res => {
        if (res.data === "No Record") {
          setLoginError(true); // Set login error state to true
        }
        else {
          handleLogin();

          sessionStorage.setItem('companyInfo', JSON.stringify({
            ID: res.data.companyID,
            name: res.data.companyName,
            fullname: res.data.companyFullName,
            mail: res.data.companyMail,
            address: res.data.companyAddress,
            phone: res.data.companyPhone,
            role : res.data.companyRole,
            serviceID : res.data.companyServiceID
          }));

        }
        console.log(res.data);
      })
      .catch(err => console.log(err));

  }


  return (
    <div id="LoginPage" className='d-flex vh-100 w-100 justify-content-center align-items-center bg-dark '>
      <div className='position-sticky w-50 d-flex flex-column align-items-center'>
        <div className='mx-auto d-block mt-3 w-75 m-3'>
          <img src="img/Peak_Logo_Kobalt.png" class="img-fluid mx-auto d-block mt-3 w-75" alt="" style={{ maxWidth: '200px' }} />
        </div>
        <div className='p-3 bg-secondary mt-3 w-100 rounded'>
          <form onSubmit={handleSubmit} className='w-100'>
            <div className='mb-3'>
              <label className="badge bg-primary text-ligt m-1" htmlFor="username">Username</label>
              <input type="username" placeholder='Enter Username' className='form-control font-monospace' onChange={e => setUsername(e.target.value)} />
            </div>

            <div className='mb-3'>
              <label className="badge bg-primary text-ligt m-1" htmlFor="password">Password</label>
              <input type="password" placeholder='Enter password' className='form-control font-monospace' onChange={e => setPassword(e.target.value)} />
            </div>
            <button className='btn btn-primary m-1 w-100'>Login</button>
            {loginError && <p id="alert" class="text-danger text-center font-weight-800">Please Check Your Username And Password!</p>}
          </form>
          <div className='text-center mt-3'>
            <Link to="/ForgotPassword" className='text-light'>Forgot Password.</Link>
          </div>
        </div>
      </div>
    </div>
  )
}

export default LoginPage
