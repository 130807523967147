import React, { useState } from 'react';
import axios from 'axios';
import { useParams, useNavigate } from 'react-router-dom';

function ResetPassword() {
  const { token } = useParams();
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [feedbackMessage, setFeedbackMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const navigate = useNavigate();

  const handleSubmit = (e) => {
    e.preventDefault();
    setErrorMessage('');
    setFeedbackMessage('');

    // Check if passwords match before submitting
    if (password !== confirmPassword) {
      setErrorMessage('Passwords do not match. Please try again.');
      return;
    }

    // If passwords match, proceed to reset the password
    axios.post(`https://portal.peaksoftcon.com:8081/resetPassword/${token}`, { password })
      .then((response) => {
        setFeedbackMessage(response.data.message);
        // Optionally redirect the user after a short delay
        setTimeout(() => navigate('/'), 3000);
      })
      .catch((error) => {
        setErrorMessage('An error occurred. Please try again later.');
      });
  };

  return (
    <div id="ResetPasswordPage" className='d-flex vh-100 w-100 justify-content-center align-items-center bg-dark '>
      <div className='position-sticky w-50 d-flex flex-column align-items-center'>
        <div className='mx-auto d-block mt-3 w-75 m-3'>
          {/* ... your logo or other UI elements ... */}
        </div>
        <div className='p-3 bg-secondary mt-3 w-100 rounded'>
          <form onSubmit={handleSubmit} className='w-100'>
            <div className='mb-3'>
              <label className="badge bg-primary text-light m-1" htmlFor="password">New Password</label>
              <input
                type="password"
                placeholder='Enter your new password'
                className='form-control font-monospace'
                value={password}
                onChange={e => setPassword(e.target.value)}
                required
              />
            </div>
            <div className='mb-3'>
              <label className="badge bg-primary text-light m-1" htmlFor="confirmPassword">Confirm New Password</label>
              <input
                type="password"
                placeholder='Re-enter your new password'
                className='form-control font-monospace'
                value={confirmPassword}
                onChange={e => setConfirmPassword(e.target.value)}
                required
              />
            </div>
            <button className='btn btn-primary m-1 w-100' type="submit">Update Password</button>
            {errorMessage && <p className="text-danger text-center mt-3">{errorMessage}</p>}
            {feedbackMessage && <p className="text-light text-center mt-3">{feedbackMessage}</p>}
          </form>
        </div>
      </div>
    </div>
  );
}

export default ResetPassword;
