import React, { useState } from 'react';
import axios from 'axios';

function ForgotPassword() {
  const [email, setEmail] = useState('');
  const [feedbackMessage, setFeedbackMessage] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();

    // Send a POST request to your server to initiate the password reset process
    axios.post('https://portal.peaksoftcon.com:8081/forgotPassword', { email })
      .then((response) => {
        // If the server responds successfully, display a success message
        setFeedbackMessage('If this email is associated with an account, a password reset link will be sent.');
        console.log(response.data);
      })
      .catch((error) => {
        // Handle error (e.g. email not found)
        setFeedbackMessage('An error occurred. Please try again later.');
        console.error(error);
      });
  };

  return (
    <div id="ForgotPasswordPage" className='d-flex vh-100 w-100 justify-content-center align-items-center bg-dark '>
      <div className='position-sticky w-50 d-flex flex-column align-items-center'>
        <div className='mx-auto d-block mt-3 w-75 m-3'>
          <img src="img/Peak_Logo_Kobalt.png" className="img-fluid mx-auto d-block mt-3 w-75" alt="" style={{ maxWidth: '200px' }} />
        </div>
        <div className='p-3 bg-secondary mt-3 w-100 rounded'>
          <form onSubmit={handleSubmit} className='w-100'>
            <div className='mb-3'>
              <label className="badge bg-primary text-light m-1" htmlFor="email">Enter your Email</label>
              <input 
                type="email" 
                placeholder='Enter your registered email' 
                className='form-control font-monospace' 
                onChange={e => setEmail(e.target.value)}
                required 
              />
            </div>
            <button className='btn btn-primary m-1 w-100' type="submit">Reset Password</button>
            {feedbackMessage && <p className="text-light text-center mt-3">{feedbackMessage}</p>}
          </form>
        </div>
      </div>
    </div>
  );
}

export default ForgotPassword;
