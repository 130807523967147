import React,{useEffect,useState} from "react";
import axios from 'axios';

import DocumentPreview from "./DocumentPreview";

const Reports = () => {
    
  const companyInfo = JSON.parse(sessionStorage.getItem('companyInfo'));

  const [documents, setDocuments] = useState([]);
  const [selectedDocument, setSelectedDocument] = useState(null);

  let companyID ;

  useEffect(() => {
    if (companyInfo) {
      let companyID = companyInfo.ID;
      axios
        .get(`https://portal.peaksoftcon.com:8081/api/documents/`, { params: { companyID} })
        .then((response) => {
          setDocuments(response.data);
        })
        .catch((error) => {
          console.error('Error fetching documents:', error);
        });
    }
  }, [companyID]);

  const handleDocumentClick = (doc) => {
    setSelectedDocument(doc);
  };
  
  return (
    <div className="container my-4">
    <h2 className="mb-4">{companyInfo.name} Raporlar</h2>
    <div className="row">
      <div className="col-md-4">
        <ul className="list-group">
          {documents.map((doc) => (
            <li
              key={doc.name}
              className={`list-group-item ${
                selectedDocument && selectedDocument.name === doc.name ? 'active' : ''
              }`}
              onClick={() => handleDocumentClick(doc)}
              style={{ cursor: 'pointer' }}
            >
              {doc.name}
            </li>
          ))}
        </ul>
      </div>
      <div className="col-md-8">
        {selectedDocument ? (
          <DocumentPreview document={selectedDocument} />
        ) : (
          <p>Please select a document to preview.</p>
        )}
      </div>
    </div>
  </div>
  );
  }
  
  export default Reports;