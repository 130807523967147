// MainHeader.js
import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useNavigate } from "react-router-dom";
import { useAuth } from "../provider/authProvider";
import { Navbar, Nav, Container } from 'react-bootstrap';

function MainHeader({ setSelectedTab }) {
  const { setToken } = useAuth();
  const navigate = useNavigate();

  const handleLogout = () => {
    setToken();
    navigate("/", { replace: true });
  };

  const handleMenuClick = (tabIndex) => {
    setSelectedTab(tabIndex);
  };

  return (
    <Navbar bg="dark" variant="dark" expand="lg" sticky="top">
      <Container>
        {/* Logo */}
        <Navbar.Brand href="#">
          <img
            src="img/Peak_Logo_Kobalt.png"
            alt="Logo"
            style={{ maxWidth: '200px', height: 'auto' }}
          />
        </Navbar.Brand>

        {/* Toggle button for mobile view */}
        <Navbar.Toggle aria-controls="main-navbar-nav" />

        {/* Navbar content */}
        <Navbar.Collapse id="main-navbar-nav">
          <Nav className="ms-auto">
            <Nav.Link onClick={() => handleMenuClick(0)}>Anasayfa</Nav.Link>
            <Nav.Link onClick={() => handleMenuClick(1)}>Raporlar</Nav.Link>
            <Nav.Link onClick={() => handleMenuClick(2)}>Bildirimler</Nav.Link>
            <Nav.Link onClick={handleLogout}>Logout</Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default MainHeader;
