import React, { useEffect,useState } from 'react';
import axios from 'axios';
import {
  TableContainer, Paper, Table, TableHead, TableRow, TableCell, TableBody,
  Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField, useMediaQuery, Card, CardContent, Typography, Grid
} from '@mui/material';

import { useTheme } from '@mui/material/styles';

function AdminCompanyEdit() {

  const [company, setCompany] = useState([]);
  const [open, setOpen] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  
  const [formData, setFormData] = useState({
    ID: '',
    Company_Name: '',
    Company_Password: '',
    Company_FullName: '',
    Company_Mail: '',
    Company_Address: '',
    Company_Phone: '',
    Company_ServiceID: '',
    role : ''
    
  });

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm')); // Responsive handling for dialog
  const isTablet = useMediaQuery(theme.breakpoints.between('sm', 'lg')); // Tablet view

  useEffect(() => {    
    axios
      .get('https://portal.peaksoftcon.com:8081/api/companies')
      .then((response) => setCompany(response.data))
      .catch((error) => console.error('Error fetching data:', error));
      
  }, []);

  const handleOpen = (company) => {
    if (company) {
      setIsEditing(true);
      setFormData(company); // Set data for editing
    } else {
      setIsEditing(false);
      setFormData({
        ID: '', Company_FullName: '', Company_Mail: '', Company_Phone: '', Company_ServiceID: '', Company_Address: ''
      });
    }
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSave = () => {
    if (isEditing) {
        // Update company via API
        axios.put(`https://portal.peaksoftcon.com:8081/api/companyUpdate/${formData.ID}`, formData)
          .then(() => {
            setCompany(prev => prev.map(t => (t.ID === formData.ID ? formData : t)));
            handleClose();
          })
          .catch(error => console.error('Error updating company:', error));
      } else {
        // Add new company via API
        axios.post('https://portal.peaksoftcon.com:8081/api/companyInsert', formData)
          .then((response) => {
            setCompany(prev => [...prev, { ...formData, ID: response.data.ID }]);
            handleClose();
          })
          .catch(error => console.error('Error adding new company:', error));
      }
  };

  const handleDelete = (id) => {
    axios.delete(`https://portal.peaksoftcon.com:8081/api/companyDelete/${id}`)
    .then(() => {
        setCompany(prev => prev.filter(company => company.ID !== id));
        console.log("Company deleted successfully");
    })
    .catch(error => console.error('Error deleting company:', error));
   
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  return (
    <div>
      <h1>Kayıtlı Şirketler</h1>
      <Button variant="contained" color="primary" onClick={() => handleOpen()}>
        Yeni Şirket Ekle
      </Button>
      {!isMobile && !isTablet ? (
        // Desktop View - Table Format
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Şirket ID</TableCell>
                <TableCell>Şirket İsmi</TableCell>
                <TableCell>Şirket Ünvanı</TableCell>
                <TableCell>Vergi Dairesi</TableCell>
                <TableCell>Vergi No</TableCell>
                <TableCell>Şirket Mail</TableCell>
                <TableCell>Şirket Adresi</TableCell>
                <TableCell>Telefon</TableCell>
                <TableCell>Hizmet ID</TableCell>
                <TableCell>İşlemler</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {(company || []).map((company) => (
                <TableRow key={company.ID}>
                  <TableCell>{company.ID}</TableCell>
                  <TableCell>{company.Company_Name}</TableCell>
                  <TableCell>{company.Company_FullName}</TableCell>
                  <TableCell>{company.Company_TaxOffice}</TableCell>
                  <TableCell>{company.Company_TaxNumber}</TableCell>
                  <TableCell>{company.Company_Mail}</TableCell>
                  <TableCell>{company.Company_Address}</TableCell>
                  <TableCell>{company.Company_Phone}</TableCell>
                  <TableCell>{company.Company_ServiceID}</TableCell>
                  <TableCell>
                    <Button variant="outlined" color="primary" onClick={() => handleOpen(company)}>
                      Düzenle
                    </Button>
                    {company.role!=='Admin' &&(
                    <Button variant="outlined" color="secondary" onClick={() => handleDelete(company.ID)} style={{ marginLeft: '8px' }}>
                      Sil
                    </Button>
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        // Tablet and Mobile View - Card Format with Adaptive Columns
        <Grid container spacing={2}>
          {(company || []).map((company) => (
            <Grid item xs={12} sm={6} md={4} key={company.ID}> {/* Stacks on mobile, 2 columns on tablet, 3 on larger screens */}
              <Card style={{ marginBottom: '16px' }}>
                <CardContent>
                  <Typography variant="h6">Şirket ID: {company.ID}</Typography>
                  <Typography>Şirket İsmi: {company.Company_Name}</Typography>
                  <Typography>Şirket Ünvanı: {company.Company_FullName}</Typography>
                  <Typography>Vergi Dairesi: {company.Company_TaxOffice}</Typography>
                  <Typography>Vergi No: {company.Company_TaxNumber}</Typography>
                  <Typography>Şirket Mail: {company.Company_Mail}</Typography>
                  <Typography>Şirket Adresi: {company.Company_Address}</Typography>
                  <Typography>Telefon: {company.Company_Phone}</Typography>
                  <Typography>Hizmet ID: {company.Company_ServiceID}</Typography>
                  <div style={{ marginTop: '8px' }}>
                    <Button variant="outlined" color="primary" onClick={() => handleOpen(company)}>
                      Düzenle
                    </Button>
                    {company.role!=='Admin' &&(
                    <Button variant="outlined" color="secondary" onClick={() => handleDelete(company.ID)} style={{ marginLeft: '8px' }}>
                      company.role
                    </Button>
                    )}
                  </div>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      )}

      <Dialog open={open} onClose={handleClose} fullScreen={isMobile} maxWidth="md" fullWidth>
        <DialogTitle>{isEditing ? "Şirketi Düzenle" : "Yeni Şirket Ekle"}</DialogTitle>
        <DialogContent>
          {['Company_Name', 'Company_Password', 'Company_FullName', 'Company_TaxOffice', 'Company_TaxNumber', 
            'Company_Mail', 'Company_Address', 'Company_Phone', 'Company_ServiceID', 'role'].map((field) => (
            <TextField
              key={field}
              label={field.replace('_', ' ')}
              name={field}
              value={formData[field]}
              onChange={handleChange}
              fullWidth
              margin="dense"
            />
          ))}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="secondary">
            İptal
          </Button>
          <Button onClick={handleSave} color="primary">
            Kaydet
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default AdminCompanyEdit;
